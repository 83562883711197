import __C from '@/primitives/_constant_'

export default {
  methods: {
    async setPasswordEnc(password_) {
      // 비밀번호를 SHA-256으로 해싱
      const passwordBuffer = new TextEncoder().encode(password_)
      const hashedPasswordBuffer = await crypto.subtle.digest(__C.CONFIG.ENC, passwordBuffer)
      const hashedPassword = this.arrayBufferToHex(hashedPasswordBuffer).toUpperCase()
      return hashedPassword
    },
    // Utility function to convert ArrayBuffer to hex string
    arrayBufferToHex(buffer) {
      return Array.from(new Uint8Array(buffer))
        .map(b => b.toString(16).padStart(2, '0'))
        .join('')
    }
  }
}